@import "~antd/dist/antd.css";

.body
{
  height:100vh;
  margin:0;
  overflow:hidden!important;
}

.App
{
  max-height:100vh;
  overflow-x:hidden!important;
  overflow-y:auto!important;
  text-align:center;
}

label span
{
  color:#ffffff!important;
  display:inline-block;
  font-size:12px;
  height:28px;
  text-align:left;
  vertical-align:middle;
  width:110px;
}

.side-bar
{
  background-color:rgb(55,55,55);
  border:0;
  display:flex;
  flex-direction:column;
  height:100vh!important;
  position:absolute;
  transition:.8s ease;
  z-index:100;
}

.nav-icons
{
  margin-bottom:18px;
  margin-top:0;
  padding:0;
  text-align:center;
  width:100%;
}

.nav-list
{
  list-style:none;
  margin:0;
  padding:0;
  width:100%;
}

.nav-list-item
{
  display:inline-block;
  height:20px;
  margin:0 20px;
  width:20px;
}

.nav-list-item a
{
  color:rgba(255,255,255,0.5)!important;
}

.side-bar2
{
  background-color:rgb(55,55,55,1);
  border:0;
  cursor:pointer;
  flex-direction:column;
  position:absolute;
  top:200px;
  transition:.1s ease;
  z-index:100;
}

p
{
  border:0!important;
}

.side-bar h3
{
  color:#ffffff!important;
  margin-bottom:20px;
}

.side-bar-close-div
{
  cursor:pointer;
  filter:opacity(0%);
  height:100%;
  margin:0;
  padding:0;
  position:absolute;
  width:100%;
  z-index:0;
}

.topbar
{
  position:absolute;
  right:10px;
  width:100px;
  z-index:310!important;
}

.toggle-menu
{
  background-color:rgb(55,55,55);
  border-bottom-right-radius:9rem;
  border-color:rgba(0,0,0,0.8);
  border-left:0;
  border-top-right-radius:10rem;
  cursor:pointer;
  height:60px;
  outline:none;
  position:fixed!important;
  width:13px;
  z-index:100;
}

.toggle-menu:active
{
  border:0;
  outline:none;
}

.App-logo
{
  animation:App-logo-spin infinite 20s linear;
  height:40in;
}

.App-header
{
  align-items:center;
  background-color:black;
  color:white;
  display:flex;
  flex-direction:column;
  font-size:calc(10px+2vmin);
  justify-content:center;
  min-height:100vh;
}

div.TextBlock
{
  margin-left:200px;
  margin-right:auto;
  max-width:75in;
  min-height:75in;
  overflow-y:hidden;
}

div.TextBlockToDoCreate
{
  margin-left:200px;
  margin-right:auto;
  max-height:10in;
  max-width:75in;
  min-height:10in;
  overflow-y:hidden;
}

form.ui.form
{
  margin:auto;
  overflow-y:hidden;
  padding:0;
  padding-top:20px;
  z-index:0;
}

.ui.form > div
{
  overflow-y:hidden;
  width:100%;
}

textarea#form-textarea-control-body
{
  background:rgba(37,37,37,0.0);
  border-bottom:0;
  border-left:1px solid;
  border-radius:0;
  border-right:0;
  border-top:0;
  cursor:text;
  font-family:PremiumUltra26, Helvetica Neue;
  font-size:26px;
  height:85in;
  margin:0;
  padding:4px 7px;
  width:100%;
  z-index:210;
}

#todolist-title
{
  font-family:PremiumUltra26, Helvetica Neue;
  font-size:42px;
  font-weight:normal;
  margin:20px;
  margin-top:30px;
  width:100%;
  z-index:210;
}

#todolist-title-sub
{
  font-family:PremiumUltra26, Helvetica Neue;
  font-size:30px;
  margin:20px;
  margin-top:30px;
  width:100%;
  z-index:210;
}

.todo-list-item
{
  background-color:rgba(0,0,0,0.15);
  border-radius:5px;
  display:flex;
  margin:10px auto;
  opacity:1;
  padding:8px 8px 4px;
  text-align:left;
  width:70%;
}

.todo-list-item input[type="checkbox"]
{
  padding:20px!important;
}

.todo-list-item span
{
  display:inline-block;
  height:100%;
  margin-left:6px;
  margin-right:4px;
  vertical-align:middle;
}

textarea#form-fieldarea-control-body
{
  background:rgba(37,37,37,0.0);
  border-bottom:0!important;
  border-left:1px solid!important;
  border-radius:0!important;
  border-right:0!important;
  border-top:0!important;
  cursor:text;
  font-family:PremiumUltra26, Helvetica Neue;
  font-size:26px;
  height:5in;
  margin:0!important;
  padding:4px 7px!important;
  width:70%;
  z-index:210!important;
}

textarea#form-fieldarea-control-body active
{
  border:0;
}

.text-info-card
{
  align-items:center;
  background:rgba(0,0,0,0.2);
  display:inline-block;
  height:26vh!important;
  margin-bottom:8px!important;
  margin-right:10px!important;
  overflow-x:clip!important;
  overflow-y:auto;
  text-align:left;
  word-wrap:break-word!important;
}

.text-info-card-tags span
{
  font-size:.9em;
  margin-bottom:3px!important;
  margin-right:-5px!important;
  margin-top:-12px!important;
  text-align:right!important;
  width:100%;
}

.text-info-card span
{
  display:block;
  font-size:.7em;
  padding-bottom:2px;
  text-align:center;
  width:100%!important;
}

.text-info-card p
{
  font-family:PremiumUltra26;
  font-size:1.5em;
  margin-top:8px;
  text-align:left!important;
}

.note-delete-button
{
  background:rgba(255,255,255,0.05)!important;
  left:-26.5vw;
  max-height:5px!important;
  max-width:5px!important;
  overflow:hidden!important;
  padding:10px!important;
  position:fixed;
  top:-24.5vh;
  z-index:99!important;
}

.App-link
{
  color:#4ec6fe;
}

.MuiToolbar-root-39.MuiToolbar-dense-42.MuiToolbar-gutters-40
{
  background-color:#252525;
  display:flex;
  justify-content:flex-end;
}

.register
{
  float:left;
  margin-left:-71px;
  margin-top:-41px;
  position:absolute;
  z-index:9999;
}

.btn-dark
{
  background-color:rgba(20,20,20,0.4)!important;
  border:0!important;
  z-index:200!important;
}

button.btn.btn-dark.btn.btn-secondary
{
  background-color:black;
  border:none;
  float:left;
  font-size:6px;
  height:12px;
  margin-left:-402px;
  margin-top:-48px;
  position:absolute;
  text-align:left;
  z-index:999;
}

.list-group-item:last-child
{
  overflow:visible;
}

.modal-body
{
  background:#38393a;
  border:1px solid #040404;
  padding:24px;
}

p:first-child
{
  border-bottom:1px solid #b5b3b3;
  color:white;
  font-size:16px;
  font-weight:800;
  margin-top:-15px;
  padding-bottom:10px;
  text-align:center;
}

pre
{
  color:#000000!important;
  display:block;
  font-size:87.5%;
}

img.img-circle
{
  background:grey;
  border:3px solid #ffffff;
  border-radius:50%;
  margin:auto 45%;
  text-align:center;
}

@media only screen and (max-width: 1400px) {
    button.btn.btn-dark.btn.btn-secondary {
        margin-left: -646px;
    }
}

@media only screen and (max-width: 1024px) {
    button.btn.btn-dark.btn.btn-secondary {
        margin-left: -465px;
    }
}

@media only screen and (max-width: 792px) {
    button.btn.btn-dark.btn.btn-secondary {
        margin-left: -365px;
    }
}

@media only screen and (max-width: 414px) {
    button.btn.btn-dark.btn.btn-secondary {
        margin-left: -165px;
    }
}

@media only screen and (max-width: 361px) {
    button.btn.btn-dark.btn.btn-secondary {
        margin-left: -163px;
    }
}